import React, { useState } from 'react';
import { useTabsWithRouter } from 'components/shared/nav/useTabsWithRouter';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch } from 'react-router-dom';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';
const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    width: '100%',
  },
}));

const Nav: React.FC<any> = ({ setMode }: any) => {
  const classes = useStyles();
  const [value, setValue] = useState('login');
  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };
  return (
    <>
      <Tabs
        value={value}
        className={classes.root}
        variant='fullWidth'
        indicatorColor={'primary'}
        onChange={handleChange}
      >
        <Tab value={'login'} label='Login' />
        <Tab value={'signup'} label='Signup' />
      </Tabs>
      {value === 'login' && <LoginForm setMode={setMode} />}
      {value === 'signup' && <SignupForm />}
    </>
  );
};

export default Nav;
