import { createSelector } from 'reselect';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

const selectStore = (state: any) => state.Loading;

const isLoadingSelector = createSelector([selectStore], (store: any) => {
  const loading = get(store, 'tasks', 0) !== 0;
  const message = get(store, 'message', 'Loading...');
  return [loading, message];
});

export const useLoading = () => {
  const loading = useSelector(isLoadingSelector);
  return loading;
};
