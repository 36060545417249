import React, { useCallback } from 'react';
import Typography from '@material-ui/core/Typography';
import { Form as FinalForm } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from 'components/shared/form/wrapper/TextField';
import useLoginParams from './useLoginParams';
import { completePasswordReset } from 'service/reset_password';
import Password from 'components/shared/form/input/Password';
import { makeValidate } from 'util/form';
import { object, string, ref } from 'yup';

const useStyles = makeStyles((theme) => ({
  form: { width: '100%', paddingTop: theme.spacing(2) },
  submit: {
    margin: theme.spacing(0, 0, 2),
    background: 'linear-gradient(to bottom right, #23ccbe, #23becc)',
    color: '#fff',
    '&:hover': {
      background: 'linear-gradient(to bottom right, #1a9a8f, #1a8f9a)',
      color: '#fff',
    },
  },
}));

interface ResetPasswordProps {
  setMode: (req: 'login' | 'init_reset' | 'complete_reset') => void;
}

const validate = makeValidate(
  object().shape({
    organization: object().shape({
      name: string(),
    }),
    user: object().shape({
      code: string().required('Code Required'),
      password: string().required('Password required').min(6).max(16),
      confirm_password: string()
        .required('Required')
        .oneOf([ref('password'), null], 'Passwords must match'),
    }),
  })
);

const CompleteResetPassword: React.FC<any> = ({
  setMode,
}: ResetPasswordProps) => {
  const {
    audience,
    client_id,
    code_challenge,
    code_challenge_method,
    nonce,
    redirect_uri,
    scope,
    state,
  } = useLoginParams();
  const onSubmit = useCallback(
    async (values) => {
      const response = await completePasswordReset({
        ...values,
        audience,
        client_id,
        code_challenge,
        code_challenge_method,
        nonce,
        redirect_uri,
        scope,
        state,
      });
      if (response.ok) {
        const body = await response.json();
        window.location.replace(body.redirect_uri);
      } else {
        return await response.json();
      }
    },
    [
      audience,
      client_id,
      code_challenge,
      code_challenge_method,
      nonce,
      redirect_uri,
      scope,
      state,
    ]
  );
  const classes = useStyles();
  return (
    <>
      <Typography variant={'h6'}>Reset Password</Typography>
      <div>
        Enter the code that was sent to your email and create a new password
      </div>
      <FinalForm
        onSubmit={onSubmit}
        //validate={validate}
        render={({ handleSubmit }) => (
          <div className={classes.form}>
            <TextField name={'code'} label={'code'} />
            <Password name={'password'} label={'Password'} />
            <Password name={'confirm_password'} label={'Confirm Password'} />
            <Button
              fullWidth
              variant='contained'
              //color='primary'
              className={classes.submit}
              onClick={handleSubmit}
            >
              Reset Password
            </Button>
          </div>
        )}
      ></FinalForm>
    </>
  );
};

export default CompleteResetPassword;
