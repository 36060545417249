import ContextCreator from '../contextCreator';
import reducer, { actionTypes } from './reducer';
import { setAuthorized } from './actions';

const { Context, Provider } = ContextCreator({
  initialState: {
    authorized: true,
    actions: {
      setAuthorized,
    },
  },
  reducer,
  actions: {
    setAuthorized,
  },
});

export { Context as default, Provider };
