export const initPasswordReset = async (credentials: any) => {
  return await fetch('/oauth/init-password-reset', {
    method: 'POST',
    body: JSON.stringify(credentials),
  });
};

export const completePasswordReset = async (credentials: any) => {
  return await fetch('/oauth/complete-password-reset', {
    method: 'POST',
    body: JSON.stringify(credentials),
  });
};
