import React, { ReactChildren } from 'react';
import clsx from 'clsx';
import Bar from '@material-ui/core/AppBar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import { appBarStyles } from './style';

interface AppBarProps {
  children?: ReactChildren | any;
  isScroll: boolean;
}

const useStyles = makeStyles(appBarStyles);

const AppBar: React.FunctionComponent<AppBarProps> = ({
  children,
  isScroll,
}) => {
  const classes: any = useStyles();
  const appBarClasses = clsx({
    [classes.root]: true,
    [classes.scroll]: isScroll,
    [classes.transparent]: !isScroll,
  });
  return (
    <Bar className={appBarClasses}>
      <Toolbar className={classes.container}>{children}</Toolbar>
    </Bar>
  );
};

export default AppBar;
