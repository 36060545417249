import React, { useEffect, useState } from 'react';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import CheckIcon from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useQueryParam } from 'util/router';
import { verify } from 'service/email';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const VerifyEmail = () => {
  const token = useQueryParam('token');
  const [verified, setVerified] = useState(false);
  useEffect(() => {
    if (token) {
      verify(token).then(res => {
        if (res.ok) {
          setVerified(true);
        }
      });
    }
  }, [token]);
  const classes = useStyles();
  const buttonClassname = clsx({
    [classes.buttonSuccess]: verified
  });
  return (
    <Container component='main' maxWidth='xs'>
      <div className={classes.paper}>
        <div className={classes.wrapper}>
          <Fab aria-label='save' color='primary' className={buttonClassname}>
            {verified ? <CheckIcon /> : <EmailOutlinedIcon />}
          </Fab>
          {!verified && (
            <CircularProgress size={68} className={classes.fabProgress} />
          )}
        </div>
        <Typography component='h1' variant='h5'>
          Verify Email
        </Typography>
      </div>
    </Container>
  );
};

export default VerifyEmail;
