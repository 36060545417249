import React, { useCallback } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import TextField from 'components/shared/form/wrapper/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeValidate } from 'util/form';
import { object, string, ref } from 'yup';
import { create } from 'service/organization';
import { FORM_ERROR } from 'final-form';
import { set } from 'lodash';
import { useCipher } from 'util/authorization';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  form: { width: '100%' },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
}));

const reduceFormErrors = (inputErrors: any[]) => {
  return inputErrors.reduce((acc: any, error: any) => {
    const { key, errors } = error;
    set(acc, key, errors);
    return acc;
  }, {});
};

const CreateForm = () => {
  let history = useHistory();
  const { handleNewOrganization } = useCipher();
  const validate = makeValidate(
    object().shape({
      organization: object().shape({
        name: string(),
      }),
      user: object().shape({
        preferred_username: string().required('Username required'),
        email: string()
          .email('Invalid email format')
          .required('Email required'),
        password: string().required('Password required').min(6).max(16),
        confirm_password: string()
          .required('Required')
          .oneOf([ref('password'), null], 'Passwords must match'),
      }),
    })
  );
  const { loginWithRedirect } = useCipher();
  const onSubmit = useCallback(
    async (organization: any) => {
      try {
        const response = await create(organization);
        if (response.ok) {
          const result = await response.json();
          await handleNewOrganization(result);
          history.push('/dashboard');
        }
        if (response.status === 400) {
          const result = await response.json();
          return reduceFormErrors(result);
        }
      } catch (e) {
        console.log(e);
        return { [FORM_ERROR]: e };
      }
    },
    [handleNewOrganization, history]
  );
  const classes = useStyles();
  return (
    <>
      <FinalForm
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <TextField name={'organization.name'} label={'Organization Name'} />
            <TextField name={'user.preferred_username'} label={'Username'} />
            <TextField name={'user.email'} label={'Email'} />
            <TextField
              name={'user.password'}
              label={'Password'}
              type='password'
            />
            <TextField
              name={'user.confirm_password'}
              label={'Confirm Password'}
              type='password'
            />
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
            >
              CREATE
            </Button>
          </form>
        )}
      ></FinalForm>
      <Grid item xs>
        <Link onClick={loginWithRedirect} variant='body2'>
          {'Already have an account? Sign in'}
        </Link>
      </Grid>
    </>
  );
};

export default CreateForm;
