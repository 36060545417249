import React, { FunctionComponent, useState, useCallback } from 'react';
import TextField from '../wrapper/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

interface PasswordInputProps {
  name: string;
  disabled?: boolean;
  label: string;
}

const PasswordInput: FunctionComponent<PasswordInputProps> = ({
  name,
  ...rest
}) => {
  const [visible, setVisible] = useState(false);
  const onVisibilityClick = useCallback(() => setVisible(!visible), [visible]);

  return (
    <TextField
      {...rest}
      name={name}
      type={visible ? 'text' : 'password'}
      copy={visible}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            {' '}
            <IconButton
              aria-label='toggle password visibility'
              onClick={onVisibilityClick}
            >
              {visible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordInput;
