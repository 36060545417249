import { isEmpty } from 'lodash';
import { combineReducers } from 'redux';

const safeCombineReducers = (reducers: any) =>
  isEmpty(reducers) ? identity : combineReducers(reducers);

const identity = (state: any) => state || null;

const withScoping = (combineFn: any) => (
  reducers: any,
  scopedReducers: any = {}
) => {
  const combinedReducer = combineFn(reducers);
  return (state: any, action: any) => {
    const { scope } = action;
    if (scopedReducers[scope]) {
      const newState = scopedReducers[scope](state, action);
      return {
        ...state,
        [scope]: newState,
      };
    }
    return combinedReducer(state, action);
  };
};

const combineReducersWithScoping = withScoping(safeCombineReducers);

export default (initialReducers: any) => {
  const reducers = { ...initialReducers };
  const scopedReducers: any = {};
  let removedKey: any;
  let combinedReducer = combineReducersWithScoping(reducers);
  return {
    reduce: (state: any, action: any) => {
      let _state = state;
      if (removedKey) {
        const { [removedKey]: removed, ...purifiedState } = state;
        _state = purifiedState;
        removedKey = undefined;
      }
      return combinedReducer(_state, action);
    },
    add: (key: string, reducer: any, isScoped = false) => {
      if (!key || reducers[key] || scopedReducers[key]) {
        return;
      }
      if (isScoped) {
        reducers[key] = identity;
        scopedReducers[key] = reducer;
      } else {
        reducers[key] = reducer;
      }
      combinedReducer = combineReducersWithScoping(reducers, scopedReducers);
    },
    remove: (key: any) => {
      if (!reducers[key] && !scopedReducers[key]) {
        return;
      }
      delete reducers[key];
      delete scopedReducers[key];
      removedKey = key;
      combinedReducer = combineReducersWithScoping(reducers, scopedReducers);
    },
  };
};
