/*eslint-disable*/
import React from 'react';
// react components for routing our app without refresh
import Link from '@material-ui/core/Link';
import clsx from 'clsx';

// @material-ui/core components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import { useCipher } from 'util/authorization';
import { useStyles } from './style';
import Divider from '@material-ui/core/Divider';

export default function MainLinks({ contrast, menu }: any) {
  const classes: any = useStyles();
  const listItemClasses = clsx({
    [classes.navListItem]: !menu,
    [classes.listItem]: true,
  });
  const { isAuthenticated, loading } = useCipher();
  return (
    <List className={classes.list}>
      <nav className={!menu ? classes.nav : ''}>
        {isAuthenticated && (
          <>
            <ListItem className={listItemClasses}>
              <Link
                href='/dashboard'
                className={classes.dropdownLink}
                variant='button'
                color='textPrimary'
              >
                Dashboard
              </Link>
            </ListItem>
            {menu && <Divider />}
          </>
        )}
        {!loading && (
          <>
            <ListItem className={listItemClasses}>
              <Link
                href='/features'
                className={classes.dropdownLink}
                variant='button'
                color='textPrimary'
              >
                Features
              </Link>
            </ListItem>
            {menu && <Divider />}
          </>
        )}
      </nav>
    </List>
  );
}
