import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import './loader.scss';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useLoading } from 'store/loading/selector';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1000,
      color: '#fff',
    },
  })
);

export const Loader: React.FC<any> = ({ size }) => {
  return (
    <div style={{ height: 4 * size }}>
      <div id='preloader' style={{ fontSize: size }}>
        <div className='preloader loading spinner'>
          <span className='slice'></span>
          <span className='slice'></span>
          <span className='slice'></span>
          <span className='slice'></span>
          <span className='slice'></span>
          <span className='slice'></span>
        </div>
      </div>
    </div>
  );
};

export const Loading: React.FC<any> = ({ open, size = 20 }) => {
  const [loading, message] = useLoading();
  const classes = useStyles();
  return (
    <Backdrop open={loading || open || false} className={classes.backdrop}>
      <div>
        <div id='preloader backdrop' style={{ fontSize: size }}>
          <div className='preloader loading spinner'>
            <span className='slice'></span>
            <span className='slice'></span>
            <span className='slice'></span>
            <span className='slice'></span>
            <span className='slice'></span>
            <span className='slice'></span>
          </div>
          <Typography variant={'h6'} style={{ marginTop: 160 }}>
            {message}
          </Typography>
        </div>
      </div>
    </Backdrop>
  );
};
