import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const containerFluid = (spacing: Function) => ({
  paddingRight: spacing(2),
  paddingLeft: spacing(2),
  marginRight: 'auto',
  marginLeft: 'auto',
  width: '100%',
});

const container = (spacing: Function) => ({
  ...containerFluid(spacing),
  '@media (min-width: 576px)': {
    marginLeft: spacing(2),
    marginRight: spacing(2),
  },
  '@media (min-width: 768px)': {
    marginLeft: spacing(3),
    marginRight: spacing(3),
  },
  '@media (min-width: 992px)': {
    marginLeft: spacing(4),
    marginRight: spacing(4),
  },
  '@media (min-width: 1200px)': {
    marginLeft: spacing(4),
    marginRight: spacing(4),
  },
});

export const brandStyles = {
  root: {},
};

export const appBarStyles = ({ palette, spacing }: Theme): any => ({
  root: {
    alignItems: 'center',
    backgroundColor: palette.background.paper,
    position: 'fixed',
    transition: 'all 0.25s cubic-bezier(0.685, 0.0473, 0.346, 1) 0s',
    width: '100%',
    zIndex: 1100,
  },
  scroll: {
    color: palette.text.primary,
  },
  transparent: {
    color: 'white',
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  container: {
    ...container(spacing),
    flex: '1',
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    flexWrap: 'nowrap',
  },
});

const styles = (theme: any): any => ({
  list: {
    fontSize: '14px',
    margin: 0,
    paddingLeft: '0',
    listStyle: 'none',
    paddingTop: '0',
    paddingBottom: '0',
    color: 'inherit',
  },
  nav: {
    display: 'flex',
    justifyContent: 'center',
  },
  listItem: {
    color: 'inherit',
    position: 'relative',
    display: 'block',
    margin: '0',
    padding: '0 0 !important',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  navListItem: {
    width: 'auto !important',
  },
  dropdownLink: {
    '&,&:hover,&:focus': {
      color: 'inherit',
      textDecoration: 'none',
      display: 'block',
      padding: '10px 20px',
    },
  },
  linkButton: {
    marginTop: 4,
    marginBottom: 4,
    marginLeft: 15,
    color: 'white',
    border: '1px solid white',
  },
  contrast: {
    color: theme.palette.text.primary,
    borderColor: theme.palette.text.hint,
  },
});
export const useStyles = makeStyles(styles);
