import React, { FunctionComponent, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import { FieldProps } from './util';
import { useScopedField } from '../hook';
import { get } from 'lodash';
import CopyToClipboard from 'react-copy-to-clipboard';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import AuthorizationContext from 'context/authorization';

const Copy = ({ endAdornment, value }: any) => {
  return (
    <>
      {endAdornment}
      <CopyToClipboard text={value}>
        <InputAdornment position='end'>
          <IconButton aria-label='copy to clipboard'>{<CopyIcon />}</IconButton>
        </InputAdornment>
      </CopyToClipboard>
    </>
  );
};

const TextFieldWrapper: FunctionComponent<FieldProps> = ({
  name,
  variant = 'outlined',
  type,
  InputProps,
  copy,
  ...rest
}) => {
  const { authorized } = useContext(AuthorizationContext);
  const {
    input: { onChange, value, ...restInput },
    meta,
    ...other
  } = useScopedField(name, {
    subscription: {
      dirtySinceLastSubmit: true,
      error: true,
      submitError: true,
      value: true,
      touched: true,
    },
  });
  const endAdornment = get(InputProps, 'endAdornment');
  const internalInputProps = copy
    ? {
        ...InputProps,
        readOnly: !authorized,
        endAdornment: <Copy endAdornment={endAdornment} value={value} />,
      }
    : { ...InputProps, readOnly: !authorized };
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;
  return (
    <TextField
      {...rest}
      {...other}
      color={'primary'}
      error={showError}
      fullWidth
      helperText={showError ? meta.error || meta.submitError : undefined}
      inputProps={restInput}
      InputProps={internalInputProps}
      margin='normal'
      name={name}
      onChange={onChange}
      value={value}
      variant={variant}
      type={type}
    />
  );
};

export default TextFieldWrapper;
