import React, { useState, useEffect, useContext } from 'react';
import createClient from 'cipher-js';
import { parseQueryParams } from './router';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const CipherContext = React.createContext<any>(undefined);

export const useCipher = () => useContext(CipherContext);
export const CipherProvider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [user, setUser] = useState();
  const [client, setClient] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  useEffect(() => {
    const initCipher = async () => {
      const clientFromHook = await createClient(initOptions);
      setClient(clientFromHook);

      if (
        window.location.search.includes('code=') &&
        window.location.search.includes('state=')
      ) {
        const { appState } = await clientFromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await clientFromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await clientFromHook.getUser();
        setUser(user);
      }

      setLoading(false);
    };
    initCipher();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleNewOrganization = async ({
    id_token,
    access_token,
    nonce,
    expires_in,
  }: any) => {
    client.loginWithToken(id_token, access_token, nonce, expires_in);
    const user = await client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await client.handleRedirectCallback();
    const user = await client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  return (
    <CipherContext.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleNewOrganization,
        handleRedirectCallback,
        getIdTokenClaims: (...p: any) => client.getIdTokenClaims(...p),
        loginWithRedirect: (...p: any) => client.loginWithRedirect(...p),
        getTokenSilently: (...p: any) => client.getTokenSilently(...p),
        getTokenWithPopup: (...p: any) => client.getTokenWithPopup(...p),
        logout: (...p: any) => client.logout(...p),
      }}
    >
      {children}
    </CipherContext.Provider>
  );
};
