import { Schema } from 'yup';
import { set, get } from 'lodash';

export const convertYupErrorsToFieldErrors = (yupErrors: any) => {
  return yupErrors.inner.reduce((errors: any, { path, message }: any) => {
    if (get(errors, path)) {
      set(errors, path, get(errors, path) + ' ' + message);
    } else {
      set(errors, path, message);
    }
    return errors;
  }, {});
};

export const convertApiErrorsToFieldErrors = (apiErrors: any[]) => {
  console.log(apiErrors);
  return apiErrors.reduce(
    (result: any, { key = 'FORM_ERROR', errors }: any) => {
      if (get(result, key)) {
        set(result, key, get(result, key) + ', ' + errors);
      } else {
        set(result, key, errors.join(', '));
      }
      console.log(result);
      return result;
    },
    {}
  );
};

export const makeValidate = <T>(validator: Schema<T>) => {
  return async (values: T) => {
    try {
      await validator.validate(values, { abortEarly: false });
    } catch (err) {
      return convertYupErrorsToFieldErrors(err);
    }
  };
};
