import React, { useLayoutEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Parallax from 'components/layout/parallax/Parallax';
import clsx from 'clsx';
import Header from 'components/layout/header/Header';
import Platform from './Platform';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import Pricing from './Pricing';

const Copyright = () => {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='https://cipheridentity.com/'>
        Cipher Identity
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  title: {
    color: '#FFFFFF',
  },
  subTitle: {
    color: '#FFFFFF',
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  main: {
    background: '#FFFFFF',
    position: 'relative',
    zIndex: 3,
  },
  mainRaised: {
    margin: '-60px 30px 0px',
    borderRadius: '6px',
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const footers = [
  {
    title: 'Platform',
    description: [
      { name: 'Team', to: '#' },
      { name: 'Capco', to: 'https://capco.com/About-Us' },
      { name: 'Contact us', to: 'https://capco.com/Contact' },
    ],
  },
  {
    title: 'Features',
    description: [
      { name: 'Digital ID', to: '#' },
      { name: 'Single Sign On', to: '#' },
      { name: 'Threat Detection', to: '#' },
    ],
  },
  {
    title: 'Resources',
    description: [
      {
        name: 'Capco Intelligence',
        to: 'https://capco.com/Intelligence/Capco-Intelligence',
      },
      { name: 'OpenID Foundation', to: 'https://openid.net/' },
      {
        name: 'Hyperledger Indy',
        to: 'https://www.hyperledger.org/projects/hyperledger-indy',
      },
    ],
  },
  {
    title: 'Legal',
    description: [
      { name: 'Privacy policy', to: '#' },
      { name: 'Terms of use', to: '#' },
    ],
  },
];

const Home = () => {
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);
  useLayoutEffect(() => {
    setLoaded(true);
  }, []);
  return (
    <>
      <Header
        color='transparent'
        brand='CIPHER IDENTITY'
        fixed
        changeColorOnScroll={{
          height: 0,
          color: 'white',
        }}
      />
      <Parallax filter image={require('../../../assets/bg1.jpg')}>
        <Container
          maxWidth='md'
          component='main'
          className={classes.heroContent}
          style={{ zIndex: 15 }}
        >
          <Fade in={loaded} timeout={750}>
            <Typography
              component='h2'
              variant='h4'
              align='center'
              color='textPrimary'
              className={classes.title}
              gutterBottom
            >
              Digital identity made simple
            </Typography>
          </Fade>
          <Fade in={loaded} timeout={750}>
            <Typography
              variant='subtitle1'
              align='center'
              color='textSecondary'
              className={classes.subTitle}
              component='p'
            >
              Integrate secure authentication and authorization enabled by
              blockchain technology
            </Typography>
          </Fade>
        </Container>
      </Parallax>
      <Slide in={loaded} direction='up'>
        <div className={clsx(classes.main, classes.mainRaised)}>
          <Platform />

          {/* End hero unit */}
        </div>
      </Slide>
      {/* Footer */}
      <Container maxWidth='md' component='footer' className={classes.footer}>
        <Grid container spacing={4} justify='space-evenly'>
          {footers.map((footer) => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography variant='h6' color='textPrimary' gutterBottom>
                {footer.title}
              </Typography>
              <ul>
                {footer.description.map((item) => (
                  <li key={item.name}>
                    <Link
                      href={item.to}
                      variant='subtitle1'
                      color='textSecondary'
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
      {/* End footer */}
    </>
  );
};

export default Home;
