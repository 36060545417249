import { useHistory } from 'react-router-dom';
import { useQueryParam } from 'util/router';

const useLoginParams = () => {
  const audience = useQueryParam('audience');
  const client_id = useQueryParam('client_id');
  const code_challenge = useQueryParam('code_challenge');
  const code_challenge_method = useQueryParam('code_challenge_method');
  const error = useQueryParam('error');
  const error_description = useQueryParam('error_description');
  const history = useHistory();
  const nonce = useQueryParam('nonce');
  const redirect_uri = useQueryParam('redirect_uri');
  const scope = useQueryParam('scope');
  const state = useQueryParam('state');
  return {
    audience,
    client_id,
    code_challenge,
    code_challenge_method,
    error,
    error_description,
    history,
    nonce,
    redirect_uri,
    scope,
    state,
  };
};

export default useLoginParams;
