"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Client = void 0;
const ClientStorage = require("./storage");
const Client_1 = require("./Client");
exports.Client = Client_1.default;
//this is necessary to export the type definitions used in this file
require("./types");
function createClient(options) {
    return __awaiter(this, void 0, void 0, function* () {
        const client = new Client_1.default(options);
        if (!ClientStorage.get('client.is.authenticated')) {
            return client;
        }
        try {
            yield client.getTokenSilently({
                audience: options.audience,
                scope: options.scope,
                ignoreCache: true
            });
        }
        catch (error) {
            if (error.error !== 'login_required') {
                throw error;
            }
        }
        return client;
    });
}
exports.default = createClient;
