import { useLocation } from 'react-router-dom';

export const useQueryParam = (param: string) => {
  const location = useLocation();
  const result = new URLSearchParams(location.search).get(param);
  return result;
};

export const parseQueryParams = (url: string) => {
  const queryStringFragments = url.split('?').slice(1);
  if (queryStringFragments.length === 0) {
    throw new Error('There are no query params available for parsing.');
  }
  let queryString = queryStringFragments.join('');
  if (queryString.indexOf('#') > -1) {
    queryString = queryString.substr(0, queryString.indexOf('#'));
  }
  return queryString.split('&').reduce((acc: any, param: string) => {
    const [key, val] = param.split('=');
    return { ...acc, [key]: decodeURIComponent(val) };
  }, {});
};
