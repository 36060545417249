import React, { createContext, useContext } from 'react';

export const ScopeContext = createContext('');

interface ScopeProviderParams {
  children: any;
  scope: string;
}

export const ScopeProvider = ({ children, scope }: ScopeProviderParams) => {
  const parentScope = useContext(ScopeContext);
  const scopeSeperator = scope.startsWith('[') ? '' : '.';
  const fullScope = `${parentScope}${scopeSeperator}${scope}`;
  return (
    <ScopeContext.Provider value={fullScope}>{children}</ScopeContext.Provider>
  );
};
