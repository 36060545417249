class AuthenticationError extends Error {
    constructor(error, error_description, state, appState = null) {
        super(error_description);
        this.error = error;
        this.error_description = error_description;
        this.state = state;
        this.appState = appState;
        //https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, AuthenticationError.prototype);
    }
}
