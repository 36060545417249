import { get, set, cloneDeep, isNil } from 'lodash';

interface Action {
  type: string;
  value: any;
}

const getPath = (root: any, path: any) => {
  let prop = root;
  const parts = path.toString().split('.');
  return parts.reduce((acc: any, part: any) => {
    return acc[part];
  }, root);
};

const generateUniqueActionID = (id: any) => {
  return id + '-' + new Date().getTime();
};

const ReducerCreator = (reducerDef: any) => {
  const actionList: any = {};
  const actionTypes: any = {};
  let actionTypeId;
  Object.keys(reducerDef).forEach((actionType) => {
    const { statePath, actionHandler } = reducerDef[actionType];
    const actionTypeId = generateUniqueActionID(actionType);
    actionTypes[actionType] = actionTypeId;
    actionList[actionTypeId] = { statePath, actionHandler };
  });

  const reducer = (state: any, action: Action) => {
    const { type, value } = action;
    const { statePath, actionHandler } = actionList[type] || {};
    let newState = cloneDeep(state);
    if (statePath) {
      const subState = get(newState, statePath);
      const newValue = actionHandler ? actionHandler(subState, value) : value;
      if (newValue !== subState) {
        return set(newState, statePath, newValue);
      }
    } else if (isNil(statePath) || statePath === '') {
      if (actionHandler) {
        newState = actionHandler(newState, value);
      }
    }
    return newState;
  };
  return { reducer, actionTypes };
};

export default ReducerCreator;
