import React, { FunctionComponent, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Nav from './Nav';
import { AltTheme } from 'Theme';
import Drawer from '@material-ui/core/Drawer';
import ResetPassword from './ResetPassword';
import CompleteResetPassword from './CompleteResetPassword';

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: '100vh',
    background: 'linear-gradient(to bottom right, #18232d, #0a0f13)',
    flexDirection: 'row-reverse',
  },
  image: {
    // backgroundImage: 'url(https://source.unsplash.com/random)',
    // backgroundRepeat: 'no-repeat',
    // backgroundColor:
    //   theme.palette.type === 'dark'
    //     ? theme.palette.grey[900]
    //     : theme.palette.grey[50],
    // backgroundSize: 'cover',
    // backgroundPosition: 'center',
  },
  content: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  drawer: {
    width: '30vw',
    maxWidth: 500,
    background: theme.palette.primary.main,
    borderLeft: 0,
  },
}));

const Login: FunctionComponent<any> = () => {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState<'login' | 'init_reset' | 'complete_reset'>(
    'login'
  );
  useEffect(() => {
    if (!open) {
      setOpen(true);
    }
  }, []);
  const classes = useStyles();
  return (
    <AltTheme>
      <Drawer
        classes={{ paper: classes.drawer }}
        variant='persistent'
        anchor='right'
        open={open}
        elevation={5}
      >
        <div className={classes.content}>
          {mode === 'login' && <Nav setMode={setMode} />}
          {mode === 'init_reset' && <ResetPassword setMode={setMode} />}
          {mode === 'complete_reset' && (
            <CompleteResetPassword setMode={setMode} />
          )}
        </div>
      </Drawer>
      <Grid container component='main' className={classes.root}></Grid>
    </AltTheme>
  );
};

export default Login;
