import React, { useState } from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import AppBar from './AppBar';
import useScrollPosition from 'util/hooks/useScroll';
import HeaderLinks from './Links';
import MainLinks from './MainLinks';
import Brand from './Brand';
// @material-ui/icons
import Menu from '@material-ui/icons/Menu';
// core components
const drawerWidth = 260;
const transition = {
  transition: 'all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)',
};

const conatinerFluid = {
  paddingRight: '15px',
  paddingLeft: '15px',
  marginRight: 'auto',
  marginLeft: 'auto',
  width: '100%',
};
const container = {
  ...conatinerFluid,
  '@media (min-width: 576px)': {
    marginLeft: 15,
    marginRight: 15,
  },
  '@media (min-width: 768px)': {
    marginLeft: 20,
    marginRight: 20,
  },
  '@media (min-width: 992px)': {
    marginLeft: 25,
    marginRight: 25,
  },
  '@media (min-width: 1200px)': {
    marginLeft: 30,
    marginRight: 30,
  },
};
const styles = ({ palette }: any): any => ({
  appBar: {
    display: 'flex',
    border: '0',
    padding: '0.625rem 0',
    marginBottom: '20px',
    width: '100%',
    backgroundColor: palette.primary.main,
    transition: 'all 0.25s cubic-bezier(0.685, 0.0473, 0.346, 1) 0s',
    alignItems: 'center',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  absolute: {
    position: 'absolute',
    zIndex: '1100',
  },
  fixed: {
    position: 'fixed',
    zIndex: '1100',
  },
  container: {
    ...container,
    minHeight: '50px',
    flex: '1',
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    flexWrap: 'nowrap',
  },
  flex: {
    flex: 1,
    justifyContent: 'center',
  },
  title: {
    lineHeight: '30px',
    fontSize: '18px',
    textTransform: 'none',
    color: 'inherit',
    padding: '8px 16px',
    letterSpacing: 'unset',
    '&:hover,&:focus': {
      color: 'inherit',
      background: 'transparent',
    },
  },
  appResponsive: {
    height: '100%',
    margin: '20px 10px',
  },
  primary: {
    backgroundColor: palette.primary.main,
    color: '#FFFFFF',
    boxShadow:
      '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(156, 39, 176, 0.46)',
  },
  info: {
    backgroundColor: palette.info.main,
    color: '#FFFFFF',
    boxShadow:
      '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(0, 188, 212, 0.46)',
  },
  success: {
    backgroundColor: palette.success.main,
    color: '#FFFFFF',
    boxShadow:
      '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(76, 175, 80, 0.46)',
  },
  warning: {
    backgroundColor: palette.warning.main,
    color: '#FFFFFF',
    boxShadow:
      '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(255, 152, 0, 0.46)',
  },
  danger: {
    backgroundColor: palette.error.main,
    color: '#FFFFFF',
    boxShadow:
      '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(244, 67, 54, 0.46)',
  },
  transparent: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none',
    color: '#FFFFFF',
  },
  dark: {
    color: '#FFFFFF',
    backgroundColor: '#212121 !important',
    boxShadow:
      '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(33, 33, 33, 0.46)',
  },
  white: {
    border: '0',
    padding: '0.625rem 0',
    marginBottom: '20px',
    color: '#555',
    backgroundColor: '#fff !important',
    boxShadow:
      '0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)',
  },
  drawerPaper: {
    border: 'none',
    bottom: '0',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    width: drawerWidth,
    position: 'fixed',
    display: 'block',
    top: '0',
    height: '100vh',
    right: '0',
    left: 'auto',
    visibility: 'visible',
    overflowY: 'visible',
    borderTop: 'none',
    textAlign: 'left',
    paddingRight: '0px',
    paddingLeft: '0',
    ...transition,
  },
});

const useStyles = makeStyles(styles);

export default function Header(props: any) {
  const classes: any = useStyles();
  const { changeColorOnScroll } = props;
  const [isScroll, setIsScroll] = useState(false);
  useScrollPosition(
    ({ currPos }: any) => {
      if (changeColorOnScroll) {
        setIsScroll(-currPos.y > changeColorOnScroll.height);
      }
    },
    [changeColorOnScroll, setIsScroll]
  );
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <AppBar isScroll={isScroll}>
      <Brand brand={'Cipher Identity'} />
      <div className={classes.flex}>
        <Hidden smDown implementation='css'>
          <MainLinks contrast={!isScroll} />
        </Hidden>
      </div>
      <Hidden smDown implementation='css'>
        <div className={classes.flex}>
          <HeaderLinks contrast={!isScroll} />
        </div>
      </Hidden>
      <Hidden mdUp>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          onClick={handleDrawerToggle}
        >
          <Menu />
        </IconButton>
      </Hidden>
      <Hidden mdUp implementation='js'>
        <Drawer
          variant='temporary'
          anchor={'right'}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerToggle}
        >
          <div className={classes.appResponsive}>
            <MainLinks contrast={false} menu={true} />
            <HeaderLinks constrast={false} menu={true} />
          </div>
        </Drawer>
      </Hidden>
    </AppBar>
  );
}

Header.defaultProp = {
  color: 'white',
};

Header.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'transparent',
    'white',
    'rose',
    'dark',
  ]),
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      'primary',
      'info',
      'success',
      'warning',
      'danger',
      'transparent',
      'white',
      'dark',
    ]).isRequired,
  }),
};
