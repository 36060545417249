import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import Login from 'components/pages/login';
import Create from 'components/pages/organization/Create';
import VerifyEmail from 'components/pages/verify-email/VerifyEmail';
import DashboardContainer from 'components/pages/dashboard/Container';
import Home from 'components/pages/home/Home';
import { useCipher } from 'util/authorization';
import { Loading } from 'components/layout/loading';
import { SnackbarProvider } from 'notistack';

const Dashboard = React.lazy(() =>
  import('components/pages/dashboard/Container')
);

function App() {
  const { loading } = useCipher();
  return loading ? (
    <Loading open={true} />
  ) : (
    <SnackbarProvider
      autoHideDuration={2000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      maxSnack={3}
    >
      <Loading />
      <Suspense fallback={<Loading open={true} />}>
        <Router>
          <Switch>
            <Route path='/login'>
              <Login />
            </Route>
            <Route path='/new-organization'>
              <Create />
            </Route>
            <Route path='/verify-email'>
              <VerifyEmail />
            </Route>
            <Route path='/dashboard'>
              <Dashboard />
            </Route>
            <Route exact path='/'>
              <Home />
            </Route>
          </Switch>
        </Router>
      </Suspense>
    </SnackbarProvider>
  );
}

export default App;
