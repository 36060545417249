import React from 'react';
import classes from '*.module.css';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import CredentialIcon from '@material-ui/icons/CardMembershipOutlined';
import PolicyIcon from '@material-ui/icons/Policy';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: theme.spacing(8, 0, 6),
  },
  subContainer: {
    marginTop: theme.spacing(4),
  },
}));

const Platform = () => {
  const classes = useStyles();
  return (
    <Container maxWidth='lg' component='main' className={classes.mainContainer}>
      <Typography
        component='h2'
        variant='h4'
        align='center'
        color='textPrimary'
        gutterBottom
      >
        Our platform
      </Typography>
      <Typography
        variant='subtitle1'
        align='center'
        color='textSecondary'
        component='p'
        gutterBottom
      >
        Leverage the benefits of decentralized identity with enterprise grade
        identity and access management tools built to scale. Our approach
        focuses on security while maximizing user privacy.
      </Typography>
      <Grid container spacing={5} className={classes.subContainer}>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant='h3' align='center' component='h3'>
            <SupervisorAccountIcon style={{ fontSize: 64 }} />
          </Typography>

          <Typography variant='h6' align='center' component='h6'>
            User Management
          </Typography>
          <Typography
            variant='subtitle1'
            align='center'
            color='textSecondary'
            component='p'
          >
            Manage user identities with streamlined flows including
            provisioning, password resets, blocking, and deleting
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant='h3' align='center' component='h3'>
            <PolicyIcon style={{ fontSize: 64 }} />
          </Typography>

          <Typography variant='h6' align='center' component='h6'>
            Access Control
          </Typography>
          <Typography
            variant='subtitle1'
            align='center'
            color='textSecondary'
            component='p'
          >
            Implement fine grained access control based on roles, groups, and
            other user attributes
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant='h3' align='center' component='h3'>
            <CredentialIcon style={{ fontSize: 64 }} />
          </Typography>

          <Typography variant='h6' align='center' component='h6'>
            Verifiable Credentials
          </Typography>
          <Typography
            variant='subtitle1'
            align='center'
            color='textSecondary'
            component='p'
          >
            Increase privacy and reduce redundancy by issuing digital
            credentials with verifiable signatures and zero knowledge proofs
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};
export default Platform;
