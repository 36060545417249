import React, { useCallback } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import TextField from 'components/shared/form/wrapper/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { login } from 'service/login';
import Button from '@material-ui/core/Button';
import { FORM_ERROR } from 'final-form';
import Password from 'components/shared/form/input/Password';
import { convertApiErrorsToFieldErrors } from 'util/form';
import FieldError from './Error';
import useLoginParams from './useLoginParams';

const useStyles = makeStyles((theme) => ({
  form: { width: '100%', paddingTop: theme.spacing(2) },
  submit: {
    margin: theme.spacing(0, 0, 2),
    background: 'linear-gradient(to bottom right, #23ccbe, #23becc)',
    color: '#fff',
    '&:hover': {
      background: 'linear-gradient(to bottom right, #1a9a8f, #1a8f9a)',
      color: '#fff',
    },
  },
  login: {},
}));

const LoginForm = ({ setMode }: any) => {
  const {
    audience,
    client_id,
    code_challenge,
    code_challenge_method,
    nonce,
    redirect_uri,
    scope,
    state,
  } = useLoginParams();
  const onSubmit = async (values: any) => {
    const response = await login({
      ...values,
      audience,
      client_id,
      state,
      nonce,
      code_challenge,
      code_challenge_method,
      redirect_uri,
    });
    if (response.ok) {
      const body = await response.json();
      window.location.replace(body.redirect_uri);
    } else if (response.status === 400) {
      const errors = await response.json();
      console.warn(errors);
      return convertApiErrorsToFieldErrors(errors);
    } else {
      return { [FORM_ERROR]: (await response.json()).error };
    }
  };
  const classes = useStyles();
  return (
    <>
      <FinalForm
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <div className={classes.form}>
            <FieldError field={'user'} />
            {!client_id ||
              (client_id === 'default' && (
                <TextField name={'organization'} label={'Organization'} />
              ))}
            <TextField name={'preferred_username'} label={'Username'} />
            <Password name={'password'} label={'Password'} />
            <Button
              fullWidth
              variant='contained'
              //color='primary'
              className={classes.submit}
              onClick={handleSubmit}
            >
              LOGIN
            </Button>
          </div>
        )}
      ></FinalForm>
      <Grid container>
        <Grid item xs>
          <Link
            onClick={() => setMode('init_reset')}
            variant='body2'
            color='textPrimary'
          >
            Forgot password?
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

export default LoginForm;
