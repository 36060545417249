import ReducerCreator from '../reducerCreator';

const { reducer, actionTypes } = ReducerCreator({
  SET_AUTHORIZED: {
    actionHandler: (state: any, authorized: boolean) => {
      return {
        ...state,
        authorized,
      };
    },
  },
});

export { reducer as default, actionTypes };
