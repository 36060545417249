"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.remove = exports.save = exports.get = exports.getAllKeys = void 0;
const Cookies = require("es-cookie");
exports.getAllKeys = () => Object.keys(Cookies.getAll() || {});
exports.get = (key) => {
    const value = Cookies.get(key);
    if (typeof value === 'undefined') {
        return;
    }
    return JSON.parse(value);
};
exports.save = (key, value, options) => {
    Cookies.set(key, JSON.stringify(value), {
        expires: options.daysUntilExpire,
    });
};
exports.remove = (key) => {
    Cookies.remove(key);
};
