import React, { useReducer, useState } from 'react';

const ContextCreator: any = ({ initialState, reducer, actions }: any) => {
  const Context: React.Context<any> = React.createContext({
    setAuthorized: () => true,
  });
  const Provider: React.FC = ({ initialValue, ...rest }: any) => {
    const defaultStateValue = initialValue || initialState;
    const [state, dispatch]: [any, any] = useReducer(
      reducer,
      defaultStateValue
    );
    const [_actions] = useState(() => {
      return Object.keys(actions).reduce((p: any, k: any) => {
        p[k] = actions[k](dispatch);
        return p;
      }, {});
    });

    const value = { ...state, ..._actions };
    return <Context.Provider {...rest} value={value} />;
  };
  return { Context, Provider };
};

export default ContextCreator;
