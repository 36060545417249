import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CreateForm from './CreateForm';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import { AltTheme } from 'Theme';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: 16,
    borderRadius: theme.shape.borderRadius,
  },
  cipher: {
    color: '#fff',
  },
  content: {
    height: '100%',
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoContainer: {
    marginLeft: '10vw',
    marginTop: '20vh',
  },
  drawer: {
    width: '30vw',
    maxWidth: 500,
    background: theme.palette.primary.main,
    borderLeft: 0,
  },
  root: {
    height: '100vh',
    background: 'linear-gradient(to bottom right, #18232d, #0a0f13)',
    flexDirection: 'row',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

const Create = () => {
  const classes = useStyles();
  return (
    <AltTheme>
      <Drawer
        classes={{ paper: classes.drawer }}
        variant='persistent'
        anchor='right'
        open={true}
        elevation={5}
      >
        <div className={classes.content}>
          <div>
            <Typography component='h1' variant='h5' className={classes.title}>
              Create Organization
            </Typography>
            <CreateForm />
          </div>
        </div>
      </Drawer>
      <Grid container component='main' className={classes.root}>
        <div className={classes.infoContainer}>
          <Typography component='h1' variant='h1' className={classes.cipher}>
            Cipher
          </Typography>
          <Typography component='h1' variant='h2' color='secondary'>
            Identity
          </Typography>
        </div>
      </Grid>
    </AltTheme>
  );
};

export default Create;
