"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_POPUP_CONFIG_OPTIONS = exports.CLEANUP_IFRAME_TIMEOUT_IN_SECONDS = exports.DEFAULT_AUTHORIZE_TIMEOUT_IN_SECONDS = void 0;
/**
 * @ignore
 */
exports.DEFAULT_AUTHORIZE_TIMEOUT_IN_SECONDS = 60;
/**
 * @ignore
 */
exports.CLEANUP_IFRAME_TIMEOUT_IN_SECONDS = 2;
/**
 * @ignore
 */
exports.DEFAULT_POPUP_CONFIG_OPTIONS = {};
