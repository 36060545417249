import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';
const header_font = 'Orbitron';
const app_purple = '#726EFF';
const app_light_teal = '#5AFFE7';
const app_main_teal = '#08C6AB';

const base = {
  palette: {
    primary: {
      main: '#344A5F',
    },
    secondary: {
      main: '#23BECC',
      contrastText: '#fff',
    },
    background: {
      default: '#F0F1F2',
    },
  },

  status: {
    danger: orange[500],
  },
  typography: {
    fontFamily: 'Montserrat',
    h1: {
      fontFamily: header_font,
      fontWeight: 600,
    },
    h2: {
      fontFamily: header_font,
      fontWeight: 500,
    },
    h3: {
      //letterSpacing: '0.1em',
      textTransform: 'uppercase',
      fontFamily: header_font,
      fontWeight: 500,
    },
    h4: {
      //letterSpacing: '0.1em',
      //textTransform: 'uppercase',
      fontFamily: header_font,
      fontWeight: 500,
    },
    h5: {
      letterSpacing: '0.1em',
      textTransform: 'uppercase',
      fontFamily: header_font,
      fontWeight: 500,
    },
    h6: {
      letterSpacing: '0.1em',
      textTransform: 'uppercase',
      fontFamily: header_font,
      fontWeight: 500,
    },
  },
};

const darkTheme = createMuiTheme({
  ...base,
  overrides: {
    MuiFormHelperText: {
      root: {
        fontSize: 14,
        '&.Mui-error': {
          marginTop: '0 !important',
          color: '#fff',
        },
      },
    },
    MuiFormLabel: {
      colorSecondary: {
        '&.Mui-error': {
          color: '#ff8a80',
        },
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: '0 !important',
        marginTop: '0 !important',
        minHeight: 85,
      },
    },
  },

  palette: {
    ...base.palette,
    primary: {
      main: '#23BECC',
    },
    secondary: {
      main: '#23BECC',
    },
    error: {
      main: '#d32f2f',
    },

    type: 'dark',
    background: {
      default: '#2E3047',
      paper: '#344A5F',
      contrast: '#726EFF',
    },
  },
});

const theme = createMuiTheme({
  ...base,
  overrides: {
    MuiFormHelperText: {
      root: {
        fontSize: 14,
        '&.Mui-error': {
          marginTop: '0 !important',
        },
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: '0 !important',
        marginTop: '0 !important',
        minHeight: 85,
      },
    },
  },
  palette: {
    ...base.palette,

    type: 'light',
    text: {
      primary: '#344A5F',
      contrast: 'FFF',
    },
  },
});

const Theme = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export const AltTheme = ({ children }) => {
  return <ThemeProvider theme={darkTheme}>{children}</ThemeProvider>;
};

export default Theme;
