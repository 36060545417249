import React, { Suspense } from 'react';
import Theme from './Theme';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { CipherProvider } from 'util/authorization';
import config from './auth_config.json';
import history from 'util/history';
import { Loading } from 'components/layout/loading';
import { Provider as ReduxProvider } from 'react-redux';
import store from 'store';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider } from 'context/authorization';
import App from './App';

const onRedirectCallback = (appState: any) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
  <ReduxProvider store={store}>
    <CipherProvider
      audience={config.audience}
      domain={config.domain}
      client_id={config.clientId}
      redirect_uri={window.location.origin.split('?')[0]}
      onRedirectCallback={onRedirectCallback}
    >
      <Theme>
        <CssBaseline />
        <Provider>
          <App />
        </Provider>
      </Theme>
    </CipherProvider>
  </ReduxProvider>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
