/*eslint-disable*/
import React from 'react';
// react components for routing our app without refresh
import Link from '@material-ui/core/Link';
import clsx from 'clsx';

// @material-ui/core components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import { useCipher } from 'util/authorization';
import { useStyles } from './style';

export default function HeaderLinks({ contrast, menu }: any) {
  const classes: any = useStyles();
  const buttonClasses = clsx({
    [classes.linkButton]: true,
    [classes.contrast]: !contrast,
  });
  const listItemClasses = clsx({
    [classes.navListItem]: !menu,
    [classes.listItem]: true,
  });
  const { isAuthenticated, loginWithRedirect, logout, loading } = useCipher();
  return (
    <List className={classes.list}>
      {!isAuthenticated && !loading ? (
        <nav className={!menu ? classes.nav : ''}>
          <ListItem className={listItemClasses}>
            <Link
              onClick={() => loginWithRedirect({})}
              className={classes.dropdownLink}
              variant='button'
              color='textPrimary'
            >
              Login
            </Link>
          </ListItem>

          <ListItem className={listItemClasses}>
            <Button
              href='/new-organization'
              variant='outlined'
              className={buttonClasses}
            >
              Sign up
            </Button>
          </ListItem>
        </nav>
      ) : (
        <nav>
          <ListItem className={classes.listItem}>
            <Link
              onClick={() => logout({ returnTo: window.location.origin })}
              className={classes.dropdownLink}
              variant='button'
              color='textPrimary'
            >
              Logout
            </Link>
          </ListItem>
        </nav>
      )}
    </List>
  );
}
