import React, { useCallback } from 'react';
import Typography from '@material-ui/core/Typography';
import { Form as FinalForm } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from 'components/shared/form/wrapper/TextField';
import useLoginParams from './useLoginParams';
import { initPasswordReset } from 'service/reset_password';

const useStyles = makeStyles((theme) => ({
  form: { width: '100%', paddingTop: theme.spacing(2) },
  submit: {
    margin: theme.spacing(0, 0, 2),
    background: 'linear-gradient(to bottom right, #23ccbe, #23becc)',
    color: '#fff',
    '&:hover': {
      background: 'linear-gradient(to bottom right, #1a9a8f, #1a8f9a)',
      color: '#fff',
    },
  },
}));

interface ResetPasswordProps {
  setMode: (req: 'login' | 'init_reset' | 'complete_reset') => void;
}

const ResetPassword: React.FC<any> = ({ setMode }: ResetPasswordProps) => {
  const { client_id, nonce } = useLoginParams();
  const onSubmit = useCallback(
    async (values) => {
      const response = await initPasswordReset({
        ...values,
        client_id,
        nonce,
      });
      if (response.ok) {
        setMode('complete_reset');
      } else {
        return await response.json();
      }
    },
    [client_id, nonce]
  );
  const classes = useStyles();
  return (
    <>
      <Typography variant={'h6'}>Reset Password</Typography>
      <div>A code to reset your password will be sent to your email</div>
      <FinalForm
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <div className={classes.form}>
            <TextField name={'email'} label={'Email'} />
            <Button
              fullWidth
              variant='contained'
              //color='primary'
              className={classes.submit}
              onClick={handleSubmit}
            >
              Send Reset Code
            </Button>
          </div>
        )}
      ></FinalForm>
    </>
  );
};

export default ResetPassword;
