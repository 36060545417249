import React from 'react';
import Typography from '@material-ui/core/Typography';
import { brandStyles } from './style';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface BrandProps {
  brand: string;
}

const useStyles = makeStyles(brandStyles);

export const Brand: React.FunctionComponent<BrandProps> = ({ brand = '' }) => {
  const classes = useStyles();
  return (
    <Typography variant='h6' color='inherit' noWrap className={classes.root}>
      {brand.toUpperCase()}
    </Typography>
  );
};

export default Brand;
