"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateCrypto = exports.getCryptoSubtle = exports.getCrypto = exports.oauthToken = exports.bufferToBase64UrlEncoded = exports.urlDecodeB64 = exports.sha256 = exports.createQueryParams = exports.decodeState = exports.encodeState = exports.createRandomString = exports.runPopup = exports.runIframe = exports.parseQueryResult = exports.getUniqueScopes = void 0;
const unfetch_1 = require("unfetch");
const constants_1 = require("./constants");
const dedupe = (arr) => arr.filter((x, i) => arr.indexOf(x) === i);
const TIMEOUT_ERROR = { error: 'timeout', error_description: 'Timeout' };
exports.getUniqueScopes = (...scopes) => {
    const scopeString = scopes.filter(Boolean).join();
    return dedupe(scopeString.replace(/\s/g, ',').split(',')).join(' ').trim();
};
exports.parseQueryResult = (queryString) => {
    if (queryString.indexOf('#') > -1) {
        queryString = queryString.substr(0, queryString.indexOf('#'));
    }
    const parsedQuery = queryString
        .split('&')
        .reduce((acc, param) => {
        const [key, val] = param.split('=');
        return Object.assign(Object.assign({}, acc), { [key]: decodeURIComponent(val) });
    }, {});
    return Object.assign(Object.assign({}, parsedQuery), { expires_in: parseInt(parsedQuery.expires_in) });
};
exports.runIframe = (authorizeUrl, eventOrigin, timeoutInSeconds = constants_1.DEFAULT_AUTHORIZE_TIMEOUT_IN_SECONDS) => {
    return new Promise((res, rej) => {
        var iframe = window.document.createElement('iframe');
        iframe.setAttribute('width', '0');
        iframe.setAttribute('height', '0');
        iframe.style.display = 'none';
        const timeoutSetTimeoutId = setTimeout(() => {
            rej(TIMEOUT_ERROR);
            window.document.body.removeChild(iframe);
        }, timeoutInSeconds * 1000);
        const iframeEventHandler = function (e) {
            if (e.origin != eventOrigin)
                return;
            if (!e.data || e.data.type !== 'authorization_response')
                return;
            e.source.close();
            e.data.response.error ? rej(e.data.response) : res(e.data.response);
            clearTimeout(timeoutSetTimeoutId);
            window.removeEventListener('message', iframeEventHandler, false);
            // Delay the removal of the iframe to prevent hanging loading status
            setTimeout(() => window.document.body.removeChild(iframe), constants_1.CLEANUP_IFRAME_TIMEOUT_IN_SECONDS * 1000);
        };
        window.addEventListener('message', iframeEventHandler, false);
        window.document.body.appendChild(iframe);
        iframe.setAttribute('src', authorizeUrl);
    });
};
const openPopup = (url) => {
    const width = 400;
    const height = 600;
    const left = window.screenX + (window.innerWidth - width) / 2;
    const top = window.screenY + (window.innerHeight - height) / 2;
    return window.open(url, 'auth0:authorize:popup', `left=${left},top=${top},width=${width},height=${height},resizable,scrollbars=yes,status=1`);
};
exports.runPopup = (authorizeUrl, config) => {
    let popup = config.popup;
    if (popup) {
        popup.location.href = authorizeUrl;
    }
    else {
        popup = openPopup(authorizeUrl);
    }
    if (!popup) {
        throw new Error('Could not open popup');
    }
    return new Promise((resolve, reject) => {
        const timeoutId = setTimeout(() => {
            reject(Object.assign(Object.assign({}, TIMEOUT_ERROR), { popup }));
        }, (config.timeoutInSeconds || constants_1.DEFAULT_AUTHORIZE_TIMEOUT_IN_SECONDS) * 1000);
        window.addEventListener('message', (e) => {
            if (!e.data || e.data.type !== 'authorization_response') {
                return;
            }
            clearTimeout(timeoutId);
            popup.close();
            if (e.data.response.error) {
                return reject(e.data.response);
            }
            resolve(e.data.response);
        });
    });
};
exports.createRandomString = () => {
    const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_~.';
    let random = '';
    const randomValues = Array.from(exports.getCrypto().getRandomValues(new Uint8Array(43)));
    randomValues.forEach((v) => (random += charset[v % charset.length]));
    return random;
};
exports.encodeState = (state) => btoa(state);
exports.decodeState = (state) => atob(state);
exports.createQueryParams = (params) => {
    return Object.keys(params)
        .filter((k) => typeof params[k] !== 'undefined')
        .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
};
exports.sha256 = (s) => __awaiter(void 0, void 0, void 0, function* () {
    const digestOp = exports.getCryptoSubtle().digest({ name: 'SHA-256' }, new TextEncoder().encode(s));
    // msCrypto (IE11) uses the old spec, which is not Promise based
    // https://msdn.microsoft.com/en-us/expression/dn904640(v=vs.71)
    // Instead of returning a promise, it returns a CryptoOperation
    // with a result property in it.
    // As a result, the various events need to be handled in the event that we're
    // working in IE11 (hence the msCrypto check). These events just call resolve
    // or reject depending on their intention.
    if (window.msCrypto) {
        return new Promise((res, rej) => {
            digestOp.oncomplete = (e) => {
                res(e.target.result);
            };
            digestOp.onerror = (e) => {
                rej(e.error);
            };
            digestOp.onabort = () => {
                rej('The digest operation was aborted');
            };
        });
    }
    return yield digestOp;
});
const urlEncodeB64 = (input) => {
    const b64Chars = { '+': '-', '/': '_', '=': '' };
    return input.replace(/[\+\/=]/g, (m) => b64Chars[m]);
};
// https://stackoverflow.com/questions/30106476/
const decodeB64 = (input) => decodeURIComponent(atob(input)
    .split('')
    .map((c) => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
})
    .join(''));
exports.urlDecodeB64 = (input) => decodeB64(input.replace(/_/g, '/').replace(/-/g, '+'));
exports.bufferToBase64UrlEncoded = (input) => {
    const ie11SafeInput = new Uint8Array(input);
    return urlEncodeB64(window.btoa(String.fromCharCode(...Array.from(ie11SafeInput))));
};
const getJSON = (url, options) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield unfetch_1.default(url, options);
    const _a = yield response.json(), { error, error_description } = _a, success = __rest(_a, ["error", "error_description"]);
    if (!response.ok) {
        const errorMessage = error_description || `HTTP error. Unable to fetch ${url}`;
        const e = new Error(errorMessage);
        e.error = error || 'request_error';
        e.error_description = errorMessage;
        throw e;
    }
    return success;
});
exports.oauthToken = (_b) => __awaiter(void 0, void 0, void 0, function* () {
    var { baseUrl } = _b, options = __rest(_b, ["baseUrl"]);
    return yield getJSON(`${baseUrl}/oauth/token`, {
        method: 'POST',
        body: JSON.stringify(Object.assign({ grant_type: 'authorization_code', redirect_uri: window.location.origin }, options)),
        headers: {
            'Content-type': 'application/json',
        },
        credentials: 'include',
    });
});
exports.getCrypto = () => {
    //ie 11.x uses msCrypto
    return (window.crypto || window.msCrypto);
};
exports.getCryptoSubtle = () => {
    const crypto = exports.getCrypto();
    //safari 10.x uses webkitSubtle
    return crypto.subtle || crypto.webkitSubtle;
};
exports.validateCrypto = () => {
    if (!exports.getCrypto()) {
        throw new Error('For security reasons, `window.crypto` is required to run `auth0-spa-js`.');
    }
    if (typeof exports.getCryptoSubtle() === 'undefined') {
        throw new Error(`
      cipher-js must run on a secure origin.
    `);
    }
};
