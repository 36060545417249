import { ScopeContext } from '../scope/ScopeProvider';
import { useContext } from 'react';
import { useField } from 'react-final-form';

const buildScope = (scope?: string, name?: string) => {
  return [scope, name].filter((s) => s).join('.');
};

export const useScopedField = (
  name: string,
  options: any = { subscription: { value: true } }
) => {
  const scope = useContext(ScopeContext);
  return useField(buildScope(scope, name), options);
};
