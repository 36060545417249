import React from 'react';
import { useField, useFormState } from 'react-final-form';
import Typography from '@material-ui/core/Typography';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles((theme) => ({
  error: { marginBottom: theme.spacing(2), color: theme.palette.error.main },
}));

const Error = ({ field }: any) => {
  const { dirtySinceLastSubmit } = useFormState({
    subscription: { dirtySinceLastSubmit: true },
  });
  const classes = useStyles();
  const { meta } = useField(field, {
    subscription: {
      error: true,
      submitError: true,
    },
  });
  const error = get(meta, 'submitError');
  return (
    <>
      <Collapse in={error && !dirtySinceLastSubmit}>
        <Typography className={classes.error}>{error}</Typography>
      </Collapse>
    </>
  );
};

export default Error;
