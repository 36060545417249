import React from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import TextField from 'components/shared/form/wrapper/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import useLoginParams from './useLoginParams';
import { register } from 'service/user';
import { convertApiErrorsToFieldErrors } from 'util/form';

const useStyles = makeStyles((theme) => ({
  form: { width: '100%', paddingTop: theme.spacing(2) },
  submit: {
    margin: theme.spacing(0, 0, 2),
    background: 'linear-gradient(to bottom right, #23ccbe, #23becc)',
    color: '#fff',
    '&:hover': {
      background: 'linear-gradient(to bottom right, #1a9a8f, #1a8f9a)',
      color: '#fff',
    },
  },
}));

const SignupForm = () => {
  const {
    client_id,
    code_challenge,
    code_challenge_method,
    nonce,
    redirect_uri,
    scope,
    state,
  } = useLoginParams();
  const onSubmit = async (values: any) => {
    const response = await register({
      ...values,
      client_id,
      state,
      nonce,
      code_challenge,
      code_challenge_method,
      redirect_uri,
    });
    if (response.ok) {
      const body = await response.json();
      if (body.approved) {
        window.location.replace(body.redirect_uri);
      } else {
        console.log('approval required');
      }
    } else if (response.status === 400) {
      const errors = await response.json();
      return convertApiErrorsToFieldErrors(errors);
    }
  };

  const classes = useStyles();
  return (
    <>
      <FinalForm
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <div className={classes.form}>
            {!client_id ||
              (client_id === 'default' && (
                <TextField name={'organization'} label={'Organization'} />
              ))}
            <TextField name={'preferred_username'} label={'Username'} />
            <TextField name={'email'} label={'Email'} />
            <TextField name={'password'} label={'Password'} type={'password'} />
            <TextField
              name={'confirm_password'}
              label={'Confirm Password'}
              type={'password'}
            />
            <Button
              fullWidth
              variant='contained'
              //color='primary'
              className={classes.submit}
              onClick={handleSubmit}
            >
              Register
            </Button>
          </div>
        )}
      ></FinalForm>
      <Grid container>
        <Grid item xs>
          <Link href='/new-organization' variant='body2'>
            {"Don't have an organization? Create an account"}
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

export default SignupForm;
