import createReducer from '../ReducerFactory';
import { ADD_TASK, REMOVE_TASK, REPORT_FATAL_ERROR } from './types';

const initialState: any = {
  tasks: 0,
  hasFatalError: true,
  message: 'Loading...',
};

const reducers = {
  [ADD_TASK]: (state: any, message: any) => ({
    ...state,
    tasks: state.tasks + 1,
    message,
  }),
  [REMOVE_TASK]: (state: any) => ({
    ...state,
    tasks: state.tasks - 1,
  }),
  [REPORT_FATAL_ERROR]: (state: any) => ({
    ...state,
    hasFatalError: true,
  }),
};

export default createReducer({ initialState, reducers });
