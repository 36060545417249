import { createStore } from 'redux';
import createReducerManager from './ReducerManager';
import Loading from './loading/reducer';
const reducerManager = createReducerManager({
  Loading,
});
const store = createStore(reducerManager.reduce);

export default store;
export const dispatch = store.dispatch;
export const getState = store.getState;
